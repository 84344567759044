import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { i18Service } from './localization';
import { DEFAULT_LOCALE } from './localization/helpers';
import history from './appHistory';
import { OidcAuthProvider } from './OIDCAuth/AuthProvider/OIDCAuthProvider';
import { store } from './store';
import runCookieScript from './cookies';
import AppRouter from './AppRouter';
import App from './App';

import './index.scss';

// INFO: Setup cookie policy
runCookieScript();

ReactDOM.render(
  <I18nextProvider i18n={i18Service.init(DEFAULT_LOCALE.id)}>
    <Provider store={store}>
      <OidcAuthProvider>
        <AppRouter history={history}>
          <App />
        </AppRouter>
      </OidcAuthProvider>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);
