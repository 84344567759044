import { pageTitle } from 'shared/constants/pageTitles';
import { OIDCROUTES } from './routes';

import Signin from '../Pages/Signin';
import Signout from '../Pages/Signout';
import SigninSilent from '../Pages/SigninSilent';
import Logout from '../Pages/Logout';

interface PathRouteProps {
  element?: React.ReactElement | null;
  path: string;
  title: string;
}

const OIDCAuthRoutesConfig: PathRouteProps[] = [
  {
    path: OIDCROUTES.signin,
    element: <Signin />,
    title: pageTitle.signin,
  },
  {
    path: OIDCROUTES.signout,
    element: <Signout />,
    title: pageTitle.signout,
  },
  {
    path: OIDCROUTES.logout,
    element: <Logout />,
    title: pageTitle.logout,
  },
  {
    path: OIDCROUTES.signinSilent,
    element: <SigninSilent />,
    title: pageTitle.signInSilent,
  },
];

export default OIDCAuthRoutesConfig;
