/* eslint-disable @typescript-eslint/no-explicit-any */
import { OIDCROUTES } from '../Routing/routes';

enum PROVIDERS {
  AZURE = 'AZURE',
  ADFS = 'ADFS',
}

const apiConfig = (window as any)?.ApiConfig || {
  CURRENT_ENV: 'DEV',
  CURRENT_OIDC_PROVIDER: PROVIDERS.AZURE,
};

const providerConfig = apiConfig[apiConfig.CURRENT_OIDC_PROVIDER] || {
  REACT_APP_OIDC_URL: '',
  REACT_APP_OIDC_CLIENT_ID: '',
  REACT_APP_OIDC_REDIRECT_URL: '',
  REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URL: '',
};

const isAdfsAuth = apiConfig?.CURRENT_OIDC_PROVIDER === PROVIDERS.ADFS;

export const OIDC_CONFIG = {
  // required config:
  authority: `${providerConfig.REACT_APP_OIDC_URL}${isAdfsAuth ? '/oauth2/authorize' : ''}`, // The URL of the OIDC/OAuth2 provider.
  client_id: providerConfig.REACT_APP_OIDC_CLIENT_ID, // Your client application's identifier as registered with the OIDC/OAuth2 provider.
  redirect_uri: `${providerConfig.REACT_APP_OIDC_REDIRECT_URL}${OIDCROUTES.signin}`, // The redirect URI of your client application to receive a response from the OIDC/OAuth2 provider.
  response_type: 'id_token token', // default: 'id_token'
  scope: `openid profile${isAdfsAuth ? ' api1' : ''}`, // default: 'openid'. The scope being requested from the OIDC/OAuth2 provider
  // end of required config
  // optional config:
  post_logout_redirect_uri: providerConfig.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URL, // The OIDC/OAuth2 post-logout redirect URI.
  automaticSilentRenew: true, // default: false. Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration. The attempt is made as a result of the accessTokenExpiring event being raised.

  silent_redirect_uri: `${providerConfig.REACT_APP_OIDC_REDIRECT_URL}${OIDCROUTES.signinSilent}`, // The URL for the page containing the code handling the silent renew.
  ...(isAdfsAuth
    ? {
        metadata: {
          issuer: providerConfig.REACT_APP_OIDC_URL,
          authorization_endpoint: `${providerConfig.REACT_APP_OIDC_URL}/oauth2/authorize`,
          userinfo_endpoint: `${providerConfig.REACT_APP_OIDC_URL}/userinfo`,
          end_session_endpoint: `${providerConfig.REACT_APP_OIDC_URL}/oauth2/logout`,
          jwks_uri: `${providerConfig.REACT_APP_OIDC_URL}/discovery/keys`,
        },
      }
    : {
        filterProtocolClaims: true,
        loadUserInfo: false,
        allowAccessTokensViaBrowser: true,
        extraQueryParams: {
          resource: providerConfig.REACT_APP_OIDC_CLIENT_ID,
        },
      }),
};
