import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TranslatedText from 'components/TranslatedText';
import ApiUrlsService from './api-urls.service';

export const toasterMessages = {
  [ApiUrlsService.startExpenseCalculation()]: 'SnackBarMessage.CalculationStarted',
  [ApiUrlsService.trueUpStartCalculation()]: 'SnackBarMessage.CalculationComplete',
  [ApiUrlsService.saveManageEmployeeContractDetails()]: 'EmployeeContractDetails.Saved',
};

export enum DefaultResponseErrorText {
  NetworkError = 'SnackBarMessage.NetworkError',
  SomethingWentWrong = 'SnackBarMessage.SomethingWentWrong',
  Unauthorized = 'SnackBarMessage.Unauthorized',
  Forbidden = 'SnackBarMessage.Forbidden',
  NotFound = 'SnackBarMessage.NotFound',
  ServiceUnavailable = 'SnackBarMessage.ServiceIsTemporarilyUnavailable',
  RequestAborted = 'SnackBarMessage.RequestAborted',
  BadRequest = 'SnackBarMessage.BadRequest',
}

const generateCheckerForSameToasterText = () => {
  let lastMsg = '';
  return (
    preventDuplicates?: boolean
  ): {
    checkMsg: (msg: string) => boolean;
    reset: () => void;
  } | null =>
    preventDuplicates
      ? {
          checkMsg: (msg) => {
            if (msg === lastMsg) return true;
            lastMsg = msg;
            return false;
          },
          reset: () => {
            lastMsg = '';
          },
        }
      : null;
};

const getDuplicatedErrorTextChecker = generateCheckerForSameToasterText();

export default class ToasterService {
  static error(text?: string, preventDuplicates = false): void {
    const resultText = text || DefaultResponseErrorText.SomethingWentWrong;
    const sameTextChecker = getDuplicatedErrorTextChecker(preventDuplicates);
    if (sameTextChecker?.checkMsg(resultText)) return;
    toast(<TranslatedText message={resultText} />, {
      toastId: this.getId(),
      position: 'bottom-center',
      onClose: sameTextChecker?.reset,
      type: 'error',
    });
  }

  static warn(text: string): void {
    toast(<TranslatedText message={text} />, {
      toastId: this.getId(),
      position: 'bottom-center',
      type: 'warning',
    });
  }

  static success(text: string): void {
    toast(<TranslatedText message={text} />, {
      toastId: this.getId(),
      position: 'bottom-center',
      type: 'success',
    });
  }

  static custom(component: React.ReactNode, options: ToastOptions = {}): void {
    toast(component, {
      ...options,
      toastId: this.getId(),
    });
  }

  private static getId = (() => {
    let counter = 1;
    return (): number => Date.now() + counter++;
  })();
}
