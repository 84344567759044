import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Selectors } from 'store/selectors';
import { SidePanel } from 'components/SidePanel/SidePanel';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import GlobalInterceptor from './shared/interceptors/global.interceptor';
import { useUserLoggedOutFromAnotherTabListener } from './shared/hooks/useUserLoggedOutFromAnotherTabListener';
import GlobalRequestLoader from './components/GlobalRequestLoader';
import Footer from './components/Layout/Footer/Footer';
import Routes from './Routes';
import Header from './components/Layout/Header/Header';
import PageContentHeader from './components/Layout/PageContentHeader/PageContentHeader';
import { SessionTimeoutModal } from './components/Modals/SessionTimeoutModal';
import CustomHooks from './shared/shared.hooks';

import '@progress/kendo-theme-default/dist/all.css';
import './App.scss';
import './KendoUI-styles.scss';
import './Toastify.scss';

import { reactPlugin } from './appinsights/appInsights';

GlobalInterceptor.init(); // init axios interceptors

const App: FC = () => {
  const isErrorLocaleKeys = useSelector(Selectors.Localization.selectIsErrorLocaleKeys);
  const isLoadedLocaleKeys = useSelector(Selectors.Localization.selectIsLoadedLocaleKeys);
  const showPrivateElements = CustomHooks.usePrivateLayout();

  useUserLoggedOutFromAnotherTabListener();

  return isErrorLocaleKeys && isLoadedLocaleKeys ? (
    <div className="serviceUnavailableMessage">Service is temporarily unavailable</div>
  ) : (
    <>
      <SessionTimeoutModal />
      <Header />
      <div className="appWrapper">
        {showPrivateElements && <SidePanel />}
        <div className="appContent">
          <main className="appContent--content">
            <GlobalRequestLoader />
            {showPrivateElements && <PageContentHeader />}
            <Routes />
          </main>
          <Footer />
        </div>
      </div>
      <ToastContainer hideProgressBar autoClose={5000} toastClassName="Deloitte-toaster" icon={false} />
    </>
  );
};

export default withAITracking(reactPlugin, App);
