import { DEFAULT_LOCALE, USER_LOCALE } from 'localization/helpers';
import axios, { CancelTokenSource } from 'axios';
import { store } from 'store';
import ApiUrlsService from './api-urls.service';
import { ILocale, ILocaleOption } from '../../models/models.dl';
import { getFromLocalStorage, setToLocalStorage } from '../../utils/localStorage';
import { UserProfile } from '../../models/models.bl';
import AuthorizationService from './authorization.service';

let localeKeysCancelToken: CancelTokenSource;

export default class Localization {
  static getLocales(): Promise<ILocale[]> {
    return axios.get(ApiUrlsService.getLocales()).then(({ data }) => data);
  }

  static getAsset(url: string): Promise<ILocale[]> {
    return axios.get(ApiUrlsService.getAsset(), { params: { urlPart: url } }).then(({ data }) => data);
  }

  static getLoginLocaleKeys(id: string): Promise<Record<string, string>> {
    return axios.get(ApiUrlsService.getLoginLocaleKeys(), { params: { localeCode: id } }).then(({ data }) => data);
  }

  static getLocaleKeys(id?: string): Promise<Record<string, string>> {
    if (localeKeysCancelToken) {
      localeKeysCancelToken.cancel();
    }
    localeKeysCancelToken = axios.CancelToken.source();

    return axios
      .get(ApiUrlsService.getLocaleKeys(), {
        params: { localeCode: id || '' },
        cancelToken: localeKeysCancelToken.token,
      })
      .then(({ data }) => data);
  }

  static getLoginLocaleCode(isError = false): string {
    if (isError) {
      return DEFAULT_LOCALE.id;
    }
    // MIP Login Page should be translated to preferred language in GASS settings,
    // that's why url parameter has higher priority than local storage code
    return Localization.getLocaleCodeFromUrl() || Localization.getLocaleCodeFromLocalStorage() || DEFAULT_LOCALE.id;
  }

  static getLocaleCode(isError = false): string {
    if (isError) {
      return DEFAULT_LOCALE.id;
    }
    return (
      Localization.getLocaleCodeFromUserConfig() || Localization.getLocaleCodeFromLocalStorage() || DEFAULT_LOCALE.id
    );
  }

  static getLocaleCodeByAuth(isAuthorized?: boolean): string {
    const authorized = typeof isAuthorized === 'boolean' ? isAuthorized : AuthorizationService.isAuthorized();
    return authorized ? Localization.getLocaleCode() : Localization.getLoginLocaleCode();
  }

  private static getLocaleCodeFromUrl(): ILocale['localeCode'] | null {
    const url = new URL(window.location.href);
    const locale: ILocale['localeCode'] | null = url.searchParams.get('locale');

    if (locale) {
      setToLocalStorage(USER_LOCALE, { id: locale, text: '' });
      url.searchParams.delete('locale');
      window.history.replaceState(null, '', url.pathname + url.searchParams.toString() + url.hash);
    }

    return locale;
  }

  private static getLocaleCodeFromUserConfig(): ILocale['localeCode'] | null {
    const user = store.getState().currentUser.user || getFromLocalStorage<UserProfile>('currentUser')?.user || null;

    return user?.settings?.locale || null;
  }

  private static getLocaleCodeFromLocalStorage(): ILocale['localeCode'] | null {
    const locale = getFromLocalStorage<ILocaleOption>(USER_LOCALE);

    return locale?.id || null;
  }
}

// MOCK RESPONSES:
// getLocales -> [{"localeCode":"en-US","nativeName":"English (United States)"},{"localeCode":"fr-CA","nativeName":"français (Canada)"}]
