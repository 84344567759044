import { createContext } from 'react';
import { UserManager } from 'oidc-client';
import { OIDCUser } from '../models/OIDCUser';
import OidcAuthService from '../service/OIDCAuthService';

export type OIDCAuthContextData = Omit<OidcAuthService, 'getTokenData'>;

const OIDCAuthContext = createContext<OIDCAuthContextData>({
  signinRedirectCallback: () => {},
  logout: () => {},
  signoutRedirectCallback: () => {},
  isAuthenticated: (): boolean => false,
  signinRedirect: () => {},
  signinSilentCallback: () => {},
  userManager: undefined as UserManager | undefined,
  getOIDCUser: () => ({} as OIDCUser),
});

export default OIDCAuthContext;
