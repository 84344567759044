import { ForwardRefRenderFunction, forwardRef, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIconFilled } from 'assets/svg/info_warning/info-filled.svg';
import { ReactComponent as InfoErrorFilled } from 'assets/svg/info_warning/error-filled.svg';
import { ReactComponent as InfoSuccessFilled } from 'assets/svg/info_warning/success-filled.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/remove.svg';
import Utils from 'shared/shared.utils';
import CustomHooks from 'shared/shared.hooks';
import Loader from 'components/Loader/Loader';
import IconTooltip from 'components/IconTooltip';
import { NotificationSeverity } from '../core/enum';
import type { INotification, INotificationProps } from '../core/interface';

import './Notification.scss';

const notificationIcon: Record<INotification['severity'], JSX.Element> = {
  [NotificationSeverity.Info]: <InfoSuccessFilled />,
  [NotificationSeverity.Warning]: <InfoIconFilled />,
  [NotificationSeverity.Error]: <InfoErrorFilled />,
  [NotificationSeverity.Success]: <InfoSuccessFilled />,
};

function useCombinedRefs<T>(...refs: any[]) {
  const targetRef = useRef<T>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

const Notification: ForwardRefRenderFunction<HTMLDivElement, INotificationProps> = (
  { uId, severity, title, message, createdDate, user, onClick, idx, setSize, onDelete, tooltipWrapper },
  ref
) => {
  const { t } = useTranslation();
  const isMounted = CustomHooks.useIsMounted();
  const innerRef = useCombinedRefs<HTMLDivElement>(ref);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (setSize && typeof idx === 'number' && innerRef.current) {
      setSize(idx, innerRef.current.getBoundingClientRect().height || 0);
    }
  }, [setSize, idx]);

  const resetLoading = () => {
    isMounted(() => {
      setLoading(false);
    });
  };

  const handleClick = () => {
    if (onClick && !loading) {
      setLoading(true);
      onClick(uId).finally(resetLoading);
    }
  };

  const handleDelete = () => {
    if (onDelete && !loading) {
      setLoading(true);
      onDelete(uId).finally(resetLoading);
    }
  };

  const { firstName = '', lastName = '' } = user || {};
  const userFullName = `by ${firstName} ${lastName}`;
  const dateString = createdDate
    ? Utils.formatDate.dateFormatter(createdDate, Utils.formatDate.DateFormat.MonthDayYearHoursMinutes, [])
    : '';
  const dateNameString = `${dateString} ${userFullName}`.trim();

  return (
    <div
      className={`NotificationCenter__item ${NotificationSeverity[severity]}${loading ? ' loading' : ''}${
        onClick ? ' clickable' : ''
      }`}
      ref={innerRef}
      onClick={onClick ? handleClick : undefined}
    >
      <header>
        {!!severity && !!notificationIcon[severity]
          ? notificationIcon[severity]
          : notificationIcon[NotificationSeverity.Success]}
        <span className="NotificationCenter__item__title">{t(title)}</span>
        {!!onDelete && (
          <IconTooltip
            icon={<DeleteIcon />}
            tooltipText={t('NotificationCenter.DeleteIconTooltip')}
            onClick={handleDelete}
            position="left"
            appendTo={tooltipWrapper?.current}
            className="DeleteNotificationTooltip"
          />
        )}
      </header>
      {!!message && (
        <span className="NotificationCenter__item__description" dangerouslySetInnerHTML={{ __html: t(message) }} />
      )}
      {!!dateNameString && <span className="NotificationCenter__item__date">{dateNameString}</span>}
      {loading && <Loader local />}
    </div>
  );
};

export default forwardRef<HTMLDivElement, INotificationProps>(Notification);
