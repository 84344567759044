import { FC, useEffect } from 'react';
import { useOIDCAuth } from '../AuthProvider/useOIDCAuth';
import Loader from '../../components/Loader/Loader';

const SigninSilent: FC = () => {
  const { signinSilentCallback } = useOIDCAuth();
  useEffect(() => {
    signinSilentCallback();
  }, [signinSilentCallback]);
  return <Loader />;
};

export default SigninSilent;
