import { User, UserManager, WebStorageStateStore } from 'oidc-client';
import Services from 'shared/shared.services';
import { ILocaleOption } from 'shared/models/models.dl';
import { getFromLocalStorage, setToLocalStorage, removeFromLocalStorage } from 'shared/utils/localStorage';
import { OIDCUser } from '../models/OIDCUser';
import { OIDC_CONFIG } from '../config/OIDC-config';
import { currentUserBrowserStorage } from '../../store/browserStorage';
import { USER_LOCALE } from '../../localization/helpers';

export default class OidcAuthService {
  public userManager: UserManager | undefined;

  constructor() {
    this.userManager = new UserManager({
      ...OIDC_CONFIG,
      userStore: new WebStorageStateStore({ store: currentUserBrowserStorage }),
    });
    this.userManager.events.addUserLoaded((user) => {
      Services.BL.AuthorizationService.resolveToken(this.getOIDCUser(user));
    });
  }

  public getOIDCUser = (user: User): OIDCUser => {
    const tokenData = this.getTokenData(user.access_token);

    return new OIDCUser(user.id_token, tokenData.unique_name, user.access_token, true);
  };

  private getTokenData = (token: string): Record<string, string> => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = (): void => {
    this.userManager?.signinRedirect({});
  };

  logout = (): void => {
    const oidcStorage = currentUserBrowserStorage.getItem(
      `oidc.user:${OIDC_CONFIG.authority}:${OIDC_CONFIG.client_id}`
    );
    this.userManager?.signoutRedirect({
      id_token_hint: oidcStorage ? JSON.parse(oidcStorage)?.id_token : '',
    });
    this.signoutRedirectCallback();
    this.userManager?.clearStaleState();
  };

  signinRedirectCallback = (): void => {
    this.userManager?.signinRedirectCallback().catch(() => {});
  };

  signinSilentCallback = (): void => {
    this.userManager
      ?.signinSilentCallback()
      .then(() => {
        if (this.isAuthenticated()) setToLocalStorage('isRefreshToken', 'true');
        else removeFromLocalStorage('isRefreshToken');
        this.userManager?.getUser().then((user) => {
          if (user) {
            Services.BL.AuthorizationService.resolveToken(this.getOIDCUser(user));
          }
        });
      })
      .catch(() => {});
  };

  signoutRedirectCallback = (): void => {
    this.userManager?.signoutRedirectCallback().then(() => {
      // TODO: rewrite this - delete only auth info
      const userLocale = getFromLocalStorage<ILocaleOption>(USER_LOCALE);
      localStorage.clear();
      if (userLocale) {
        setToLocalStorage(USER_LOCALE, userLocale);
      }
    });
    this.userManager?.clearStaleState();
    this.userManager?.removeUser();
  };

  isAuthenticated = (): boolean => {
    // return your custom check or this.userManager.is
    return Services.BL.AuthorizationService.isAuthorized();
    // const oidcStorage = JSON.parse(
    //   currentUserBrowserStorage.getItem(`oidc.user:${OIDC_CONFIG.authority}:${OIDC_CONFIG.client_id}`)
    // );

    // return !!oidcStorage?.access_token;
  };
}
