import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsLoadedLocaleKeys } from 'store/localization/selectors';
import { ReactComponent as SettingsIcon } from 'assets/svg/settings.svg';
import { ReactComponent as ExitIcon } from 'assets/svg/exit.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { useLogoutFromApp } from 'shared/hooks/useLogoutFromApp';
import { useAppSelector } from 'shared/hooks/storeHooks';
import { activityLogs } from 'shared/constants/routeLinkOptions';
import IconTooltip from 'components/IconTooltip';
import { UserLanguageSelect } from 'components/LanguageSelect/UserLanguageSelect/UserLanguageSelect';
import UserAvatar from '../../UserAvatar/UserAvatar';
import NavItem from '../../NavItem/NavItem';
import UserSettingsModal from '../../Modals/UserSettingsModal';
import { Sidebar } from './UserMenuSideBar';
import './UserMenuIcon.scss';

const className = 'userMenuIcon';

const UserPanel: FC = () => {
  const { t } = useTranslation();
  const logout = useLogoutFromApp();
  const user = useAppSelector((state) => state.currentUser.user);
  const isLoadedLocaleKeys = useSelector(selectIsLoadedLocaleKeys);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(!user?.settings?.locale);
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseSettings = useCallback(() => {
    setIsSettingsModalOpen(false);
  }, []);

  const { firstName, lastName } = user || {};

  const userName = `${firstName} ${lastName}`;

  const handleLogout = () => {
    logout();
  };
  const handleOpenSettings = () => {
    setIsSettingsModalOpen(true);
  };
  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <IconTooltip
        icon={<UserAvatar src="" userName={userName} />}
        tooltipText={userName}
        position="bottom"
        onClick={handleClick}
      />
      {isOpen && (
        <Sidebar>
          <>
            <div className={`${className}__user`}>
              <div>
                <CloseIcon className="crossIcon" onClick={onClose} />
              </div>
              <div className={`${className}__user__avatar`}>
                <UserAvatar src="" userName={userName} />
              </div>
              <div className={`${className}__user__info`}>
                {!!userName && (
                  <span title={userName} className={`${className}__user__info__name`}>
                    {userName}
                  </span>
                )}
              </div>
            </div>
            <hr className="userProfile-border" />
            {isLoadedLocaleKeys && (
              <>
                <div className={`${className}__content`}>
                  <div className={`${className}__content__languageSelect`}>
                    <UserLanguageSelect isOpen={isOpen} />
                  </div>
                  <hr className="userProfile-border" />
                  <NavItem
                    to=""
                    iconStart={<SettingsIcon />}
                    className="navitemBorder"
                    iconStartColor="grey"
                    label={t('UserMenu.Settings')}
                    onClick={handleOpenSettings}
                  />

                  {activityLogs.routes.map(({ label, ...item }, idx) => (
                    <NavItem
                      {...item}
                      className="navitemBorder"
                      label={t(label as string)}
                      onClick={onClose}
                      key={`${label}-${idx}`}
                    />
                  ))}
                  <NavItem
                    to=""
                    className="navitemBorder"
                    iconStart={<ExitIcon />}
                    iconStartColor="grey"
                    label={t('UserMenu.LogOutButton')}
                    onClick={handleLogout}
                  />
                </div>
              </>
            )}

            {isSettingsModalOpen && <UserSettingsModal onClose={handleCloseSettings} />}
          </>
        </Sidebar>
      )}
    </>
  );
};

export default UserPanel;
