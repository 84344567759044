import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DisplayContentModal from 'components/Modals/DisplayContentModal/DisplayContentModal';
import Button from 'components/Button';
import FilesService from 'shared/services/bl/files.service';
import { selectIsLoadedLocaleKeys } from 'store/localization/selectors';
import { PublicFileCode } from 'shared/models/enums.dl';
import LocalizationService from 'shared/services/bl/localization.service';
import CustomHooks from 'shared/shared.hooks';
import { selectCurrentUser } from 'store/user/selectors';
import 'components/Layout/Footer/CountrySpecificPrivacyLink/CountrySpecificPrivacyLink.scss';
import 'components/Modals/DisplayContentModal/DisplayContentModal.scss';

const CountrySpecificPrivacyLink: FC = () => {
  const isLoadedLocaleKeys = useSelector(selectIsLoadedLocaleKeys);
  const { t } = useTranslation();
  const { isAuthenticated } = CustomHooks.useOIDCAuth();
  const isAuth = isAuthenticated();
  const [isRequiredModalShow, setIsRequiredModalShow] = useState(false);
  const user = useSelector(selectCurrentUser);
  const localeCode = LocalizationService.getLocaleCodeByAuth(isAuth);
  const [loggedInCountry, setLoggedInCountry] = useState('');
  const [countrySpecificLink, setCountrySpecificLink] = useState('/#');
  const onRequiredModalCloseHandler = () => {
    setIsRequiredModalShow(false);
  };

  const AUPrivacyLink = 'https://www.deloitte.com/au/en/legal/privacy.html?icid=bottom_privacy';
  const SGPrivacyLink = 'https://www2.deloitte.com/sg/en/footerlinks1/privacy.html?icid=bottom_privacy';
  const MYPrivacyLink = 'https://www2.deloitte.com/my/en/footerlinks1/privacy.html?icid=bottom_privacy';

  enum CountryCode {
    Australia = 'aus',
    Singapore = 'sgp',
    Malaysia = 'mys',
  }

  useEffect(() => {
    switch (user?.user?.countryCode) {
      case CountryCode.Australia.toString().toLowerCase(): {
        setLoggedInCountry('Australia');
        setCountrySpecificLink(AUPrivacyLink);
        break;
      }
      case CountryCode.Singapore.toString().toLowerCase(): {
        setLoggedInCountry('Singapore');
        setCountrySpecificLink(SGPrivacyLink);
        break;
      }
      case CountryCode.Malaysia.toString().toLowerCase(): {
        setLoggedInCountry('Malaysia');
        setCountrySpecificLink(MYPrivacyLink);
        break;
      }
      default: {
        break;
      }
    }
  }, []);

  return (
    <>
      {isLoadedLocaleKeys && (
        <>
          <a
            href="/"
            onClick={(event) => {
              event.preventDefault();
              setIsRequiredModalShow(true);
            }}
          >
            {t('CountrySpecificPrivacyLink.LinkName')}
          </a>
          <DisplayContentModal
            title={t('CountrySpecificPrivacyLink.LinkName')}
            visible={isRequiredModalShow}
            onClose={onRequiredModalCloseHandler}
            className="displayLinkModalLayout"
          >
            <div className="border-line">
              <div className="links-container">
                <a
                  href={FilesService.getPublicFileLink(PublicFileCode.PrivacyPolicy, localeCode)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Footer.Privacy.GlobalPrivacyStatement')}
                </a>
                <br />
                {loggedInCountry ? (
                  <a href={countrySpecificLink} target="_blank" rel="noopener noreferrer">
                    {loggedInCountry} {t('Footer.Privacy.PrivacyStatement')}
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="modal-btn-display">
              <Button color="primary" onClick={onRequiredModalCloseHandler} styleType="default">
                {t('DisplayContentModal.CloseButton')}
              </Button>
            </div>
          </DisplayContentModal>
        </>
      )}
    </>
  );
};

export default CountrySpecificPrivacyLink;
