import { useCallback } from 'react';
import { useOIDCAuth } from 'OIDCAuth/AuthProvider/useOIDCAuth';
import UserDataService from '../services/dl/user.data.service';
// import AuthenticationService from '../services/bl/authentication.service';

export const useLogoutFromApp = (/* deleteUser = true */): (() => Promise<void>) => {
  const { logout: OIDCLogout } = useOIDCAuth();
  return useCallback(
    () =>
      UserDataService.clearUserCash().then(() => {
        // AuthenticationService.logOut(deleteUser);
        OIDCLogout();
      }),
    [OIDCLogout]
  );
};
