import { FC, useEffect } from 'react';
import { useOIDCAuth } from '../AuthProvider/useOIDCAuth';
import Loader from '../../components/Loader/Loader';

const Signin: FC = () => {
  const { signinRedirectCallback } = useOIDCAuth();
  useEffect(() => {
    signinRedirectCallback();
  }, [signinRedirectCallback]);

  return <Loader />;
};

export default Signin;
