import useOnClickOutside from './hooks/useOnClickOutside';
import useDebounce from './hooks/useDebounce';
import useTable from './hooks/useTable';
import { useOIDCAuth } from '../OIDCAuth/AuthProvider/useOIDCAuth';
import usePagination from './hooks/usePagination/usePagination';
import useForfeitureRate from './hooks/useForfeitureRate';
import { useTableActiveState } from './hooks/useTableActiveState';
import { useModalActiveState } from './hooks/useModalActiveState';
import { useIsFormModified } from './hooks/useIsFormModified';
import { useSummaryReportViewState } from './hooks/useSummaryReportViewState';
import * as storeHooks from './hooks/storeHooks';
import { useDataState } from './hooks/useDataState';
import { useDefaultFilterOption } from './hooks/useDefaultFilterOption';
import { usePrivateLayout } from './hooks/usePrivateLayout';
import useIsMounted from './hooks/useIsMounted';
import { useFileUploadingState } from './hooks/useFileUploadingState';
import { useLogoutFromApp } from './hooks/useLogoutFromApp';
import { useUploaderWithDataConfirmation } from './hooks/useUploaderWithDataConfirmation';
import { useDownloadPrivateFile } from './hooks/useDownloadPrivateFile';

const CustomHooks = {
  useOnClickOutside,
  useDebounce,
  useTable,
  useOIDCAuth,
  usePagination,
  useForfeitureRate,
  useTableActiveState,
  useModalActiveState,
  useIsFormModified,
  useSummaryReportViewState,
  storeHooks,
  useDataState,
  useDefaultFilterOption,
  usePrivateLayout,
  useIsMounted,
  useFileUploadingState,
  useLogoutFromApp,
  useUploaderWithDataConfirmation,
  useDownloadPrivateFile,
};

export default CustomHooks;
