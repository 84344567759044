import { FC, useEffect } from 'react';
import { useOIDCAuth } from '../AuthProvider/useOIDCAuth';
import Loader from '../../components/Loader/Loader';

const Signout: FC = () => {
  const { signoutRedirectCallback } = useOIDCAuth();
  useEffect(() => {
    signoutRedirectCallback();
  }, [signoutRedirectCallback]);
  return <Loader />;
};

export default Signout;
