import { FC, useEffect } from 'react';
import { useOIDCAuth } from '../AuthProvider/useOIDCAuth';
import Loader from '../../components/Loader/Loader';

const Logout: FC = () => {
  const { logout } = useOIDCAuth();
  useEffect(() => {
    logout();
  }, [logout]);

  return <Loader />;
};

export default Logout;
